import { MenuEntry } from '@mozartfinance/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Exchange',
    icon: 'TradeIcon',
    href: '/swap',
  },
  {
    label: 'Liquidity',
    icon: 'PoolIcon',
    href: '/pool',
  },
  /*
  {
    label: 'Launchpad',
    icon: 'MoreIcon',
    href: 'launchpads',
  },
  {
    label: 'Airdrop',
    icon: 'MoreIcon',
    href: '/airdrops',
  },
  {
    label: 'MATS Token',
    icon: 'MoreIcon',
    href: 'https://bscscan.com/token/0xd8b934ea8e6FC11AB563276dda236B9FEaf5fF87',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://mozartfinance.io/pools',
  },
  {
    label: 'Audit by IB',
    icon: 'TicketIcon',
    href: 'https://github.com/ImmuneBytes/Smart-Contract-Audit-Reports/blob/main/Mozart%20Finance/Mozart%20Finance%20(PIANO)%20-%20Final%20Audit%20Report.pdf',
  }, */
]

export default config
